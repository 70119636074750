import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layouts"

export const query = graphql`
  {
    prismic {
      allBlogs(uid: null) {
        edges {
          node {
            meta_title
            _meta {
              uid
              id
              type
            }
          }
        }
      }
      allBlog_posts {
        edges {
          node {
            _meta {
              uid
              id
              type
            }
            title
            publish_date
            image
          }
        }
      }
    }
  }
`

const RenderPosts = ({ posts }) => {
  return posts.map(item => (
    <article key={item.node._meta.uid} className="blog-home-post-wrapper">
      {item.node.image ? (
        <Link
          to={linkResolver(item.node._meta)}
          className="blog-home-post-link"
        >
          <img
            className="blog-home-post-image"
            src={item.node.image.url}
            alt={item.node.image.alt}
          />
        </Link>
      ) : (
        <Link
          to={linkResolver(item.node._meta)}
          className="blog-home-post-link"
        >
          <span className="blog-home-post-fallback" />
        </Link>
      )}
      <Link to={linkResolver(item.node._meta)}>
        <div className="blog-home-post-item">
          <p className="blog-home-post-date">{item.node.publish_date}</p>
          <p className="blog-home-post-title">
            {RichText.asText(item.node.title)}
          </p>
        </div>
      </Link>
    </article>
  ))
}

const RenderBody = ({ blogHome, posts }) => (
  <React.Fragment>
    <section className="blog-home-section">
      <div className="blog-home-posts-wrapper">
        <RenderPosts posts={posts} />
      </div>
    </section>

    <div data-wio-id={blogHome._meta.id} />
  </React.Fragment>
)

export default ({ data }) => {
  const doc = data.prismic.allBlogs.edges.slice(0, 1).pop()
  if (!doc) return null

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{RichText.asText(doc.node.meta_title)}</title>
      </Helmet>
      <RenderBody
        blogHome={doc.node}
        posts={data.prismic.allBlog_posts.edges}
      />
    </Layout>
  )
}
